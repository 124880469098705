
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
const Admission = () => {
  useEffect(() => {
    document.title = 'Admission - Mar Gregorios Memorial Hr. Sec. School, Bokaro';
  }, []);
  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li>
          <li>  Academic</li>
          <li>Admission </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Admission </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Admission to our school is strictly based on merit. We do not charge capitation fees, donations, or any other forms of payment. Parents/Guardians are cautioned against individuals posing as 'agents' who claim to secure admission in exchange for money.</p>
              <p><b>Please note that:</b></p>
              <ul className="ullist">
              <li>The school has no authorized agents.</li>
              <li>No donations, whether cash or kind, are accepted for admission, promotion, or any other purposes.</li>
              </ul>
              <h3 className="subhead">Admission and Withdrawal</h3>
              <p>Our School is a Minority Community Institution and does not come under RTE Act.</p>
              <h3 className="subhead">Admissions for Classes I - IX</h3>
              <p>Online Application form will be issued through school website <a href="https://www.mgmhssbokaro.in/" target="_blank" style={{ textDecoration: "none" }}><strong>www.mgmhssbokaro.in</strong> </a> for the available seats from the month of October onwards.</p>
              <h3 className="subhead">Admissions to Class XI</h3>
              <p>Admissions to Class XI is purely on the basis of Gregorian Entrance Test (GET) and result of Board Examination. Students has to apply online through <a target="_blank" href="http://www.mgmbcampuscare.in/">mgmbcampuscare.in</a> or through our website - <a target="_blank" href="http://www.mgmbcampuscare.in/"> mgmbcampuscare.in</a> MGM students will be given first preference. The final selection will be based on a written test, personal interview and Board marks. Cut – off percentage / grade will be fixed at the discretion of school authority.</p>
              <h3 className="subhead">Documents Required at the time of Interview &amp; Admission</h3>
              <ul className="ullist">
                <li>Transfer Certificate (in original)</li>
                <li>Character Certificate (in original)</li>
                <li>Photo copy of Class – X Mark Sheet (if not submitted at the time of registration)</li>
                <li>Migration Certificate in original (applicable to the students other than C.B.S.E Board)</li>
                <li>One recent passport size photograph.</li>
                <li>Xerox of Adhar Card.</li>
              </ul>
              <div className="clr"></div>
              <h3 className="subhead">Withdrawals from the School</h3>
              <p>A notice period of at least one calendar month is required to be given in case for the withdrawal of the pupil from the School. Leaving Certificate shall not be issued until all the dues of the school is cleared.</p>
              <p>Except on transfer case, leaving Certificate shall not be issued after the expiry of the stipulated period (March-June). A fee of Rs 250/- shall be charged for the issue of Transfer certificates.</p>
              <h3 className="subhead">Eligible Age for Admission</h3>
              <p>A child must be 5 ½ years old as on 31st March in the academic year in which admission is sought for Class 1. (Child born on 1st April should also be considered.)</p>
              <p> <strong>A. </strong>The minimum and maximum age limit for admission in M.G.M. Higher Secondary School in various classes is given below. <strong>(Child born on 1st April should also be considered.)</strong></p>
              <div className="clr10"></div>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Class</th>
                      <th>Minimum Age on 31st March of the year in which admission is sought</th>
                      <th>Maximum Age on 31st March of the year in which admission is sought</th>
                    </tr>
                    <tr>
                      <td>I</td>
                      <td>5 Years 6 months </td>
                      <td>6 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>II</td>
                      <td>6 Years 6 months </td>
                      <td>7 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>III</td>
                      <td>7 Years 6 months </td>
                      <td>8 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>IV</td>
                      <td>8 Years 6 months </td>
                      <td>9 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>V</td>
                      <td>9 Years 6 months </td>
                      <td>10 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>VI</td>
                      <td>10 Years 6 months </td>
                      <td>11 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>VII</td>
                      <td>11 Years 6 months </td>
                      <td>12 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>VIII</td>
                      <td>12 Years 6 months </td>
                      <td>13 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>IX</td>
                      <td>13 Years 6 months </td>
                      <td>14 Years 6 months</td>
                    </tr>
                    <tr>
                      <td>XI</td>
                      <td>15 Years 6 months </td>
                      <td>16 Years 6 months</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Admission

