import React, { useEffect  } from "react";
import { Link } from 'react-router-dom' 
const ClassRoom = () => {
    useEffect(() => {
        document.title = 'Class Room - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Infrastructure </li>
                 <li>Class Room</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Class Room</h1>
                    </div>
                </div>
                <div className="row"> 
                   <div className="col-12"> 
                        <h3 className="subhead text-center">Studies in Smart Classes </h3>
                        <p>Edu-comp Smart classes are introduced from classes I to class XII, wherein the visual impacts are expected to be highly beneficial to the students in the longer run. Respective Class Teachers and Class Monitors are assigned the duty of handling the system. Students are advised not to cause any damage to the Edu-comp system as any such instance shall attract heavy fines.</p>
                            <p><strong>We have 100 Classrooms, 2 Lecture Theatres, 1 Multi-purpose hall and 1 i-school.</strong></p>
                        <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm1.jpg">
                            <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm1.jpg" alt="mgmb"/>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm2.jpg">
                            <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm2.jpg" alt="mgmb"/>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm3.jpg">
                            <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm3.jpg" alt="mgmb"/>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm4.jpg">
                            <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/clsrm4.jpg" alt="mgmb"/>
                            </a>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default ClassRoom

