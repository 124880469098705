import React, {useEffect } from 'react';
import { Link } from 'react-router-dom'
const PrayerAnthem = () => {
  useEffect(() => {
    document.title = ' Prayer Anthem - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Prayer Anthem</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Prayer Anthem  </h1>
                    </div>
                    <div className="col-lg-12">
                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/1WUQddF5Vd4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    </div>
                </div>
                <div className="overview">
                <div className="row color-overlay">
                  <div className="col-lg-6 col-md-6">
                    <h3 className="midhead">MGM Anthem</h3>
                    <p> ज्ञानशील करुणामयी <br/> अनुशासन सर्वोपरी। <br/>  भवित्यता प्रवाहमयी, <br/>  एम.जी.एम सदा विजयी। <br/> 
                    प्रबल प्रगतिशील हम बने, <br/>  संत ग्रिगोरियस के पथ चले।  <br/>  शिष्टता शांतिमय, तमसोमा ज्योतिर्गमय <br/>  सुसंस्कृत प्रशंसनीय, तमसोमा ज्योतिर्गमय <br/> 
                    सत्यं वद, धर्मम् चर, <br/>  सद्भावना तेजोमय। <br/>  अद्वितीय आनंदमय, <br/>  एम.जी.एम सदा विजयी। </p>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <h3 className="midhead">Opening Prayer</h3>
                    <p>Eternal God, Creator of all<br/>
                      I ask Thee<br/>
                      To enlighten my mind<br/>
                      That I may know Thee<br/>
                      And my neighbours<br/>
                      And Love my neighbours for<br/>
                      Thy sake.</p>
                  </div>  
                    
                </div>
           </div>
            </div>
        </div>
     </>
  )
}

export default PrayerAnthem

