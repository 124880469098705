import React, { useState, useEffect } from 'react';
import { getVisitor, postVisitor } from '../Service/Api';

const VisitorCounter = () => {
  const [visitorCount, setVisitorCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const incrementVisitor = async () => {
      await postVisitor(); // Trigger the POST request to increment the visitor count
      fetchVisitorCount(); // Fetch the updated visitor count after posting
    };
    
    const fetchVisitorCount = async () => {
      try {
        const data = await getVisitor();
        console.log("Visitor Count", data);
        if (data.length > 0) {
          setVisitorCount(data[0].visitor); // Extract and set the visitor value from the first object in the array
        }
      } catch (error) {
        console.log("Error fetching visitor count:", error);
      } finally {
        setLoading(false);
      }
    };

    incrementVisitor(); // Trigger the POST and GET requests on component mount
  }, []);

 

  return (
    <>
    <div className='visitor-count'>Visitor count: {visitorCount}  </div>
       
    </>
  );
};

export default VisitorCounter;