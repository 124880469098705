import React from 'react'
import { Link } from 'react-router-dom' 
import Slider from "react-slick";
const Infrastructure = () => {
 

  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:1,
            slidesToScroll: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
     <> 
     <div className="row">
     <Slider {...settings}>
   
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab1.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">
                   
                    <h6>Tinkering Lab</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/smartClass.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">
                   
                    <h6>Smart Classes</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/languagelab.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">
                   
                    <h6>Language Lab</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/class1.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">
                    
                    <h6>Class Room</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/lab.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">
                     
                    <h6>Laboratories</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/playground.jpg" className="img-fluid" alt="MGM bokaro"/>
                <div className="gallerydesc">                    
                    <h6>Playground</h6> 
                </div>
            </div>
          </div>
        </div>
       </Slider>
     </div>
     </>
  )
}

export default Infrastructure