import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const Chairman = () => {
    useEffect(() => {
        document.title = 'Our Chairman - H.G. Alexios Mar Eusebius';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>School</li>
                 <li>Our Chairman</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Chairman </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/chairman1.png" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p>Welcome all of you to a new academic year that can take you to higher levels of learning experience and happiness. Start on the new session with dedication and decisiveness to excel not only in academics but also in character. Intelligence plus character-that is the goal of true education.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Chairman

