import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/MGMB`);
    console.log(data)
    return data;
} 
export const getHighlightAll = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/all/MGMB`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/MGMB`);
    console.log("News" ,data)
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/MGMB`);
    console.log(data)
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/MGMB`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/MGMB`);
    console.log("innerEvents" ,data)
    return data;
}
 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/All/MGMB`);
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/MGMB`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/MGMB`);
    console.log("galleryinner" , data);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/MGMB`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/MGMB`,getHeader());
    console.log("topper", data)
    return data;
} 

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/MGMB`);
    console.log("Birthday" ,data)
    return data;
}
export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/MGMB`,getHeader());
    return data;
}  
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/MGMB`, getHeader());
    console.log("circular" ,data);
    return data;
} 

export const getActivity = async() => {
    const { data } = await axios.get(API_URL + `/api/activity/all/MGMB`, getHeader());
    console.log("activity" ,data);
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/MGMB`, getHeader());
    return data;
} 

  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/MGMB`,{name,admission},getHeader());
    return data;
}
  
export const postVisitor = async() => {
  const { data } = await axios.post(API_URL + `/api/visitor`, { schoolcode: "MGMB" }, getHeader());
  console.log(data)
  return data;
}
export const getVisitor= async() => {
  const { data } = await axios.get(API_URL + `/api/visitor/MGMB`, getHeader());
  console.log("service code", data)
  return data;
}
