
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom' 
const ActivityCorner = () => {
    useEffect(() => {
        document.title = 'Activity Corner - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Educational Activities</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Educational Activities</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    
                    <p>All-round multifarious development of the children is the motto of the educational process in M.G.M. Hidden talents and potentials of each and every student is discovered and given exposure. Keeping that in mind various opportunities for students to take part in Extra and Co-curricular activities are provided in the school.</p>
                    <h3 className="subhead">Students are encouraged to take part in various activities like:</h3>
                    <ul className="ullist">
                        <li> <b>Sports & Games : </b> Coaching in Athletics, Cricket, Basket Ball, Football, Badminton, Table Tennis, Archery, Judo, Rope Skipping, Chess, Volley Ball, Kho-Kho etc. for classes VI-XII.</li>
                        <li><b>Yoga :</b>  Classes ( Vth -XIIth)</li>
                        <li><b>Martial Art :</b> Classes ( IVth -XIIth) </li>
                        <li><b> Debate / Elocution Competitions : </b> Classes (IVth - XIIth )  (English & Hindi)</li>
                        <li><b>Drawing & Painting :</b> Classes ( Ist - XIIth)</li>
                        <li><b> Music & Dance : </b>Classes ( Ist - XIIth)</li>
                        <li><b>Quiz Competition / Olympiads :</b>  Classes Ist - XIIth</li>
                    </ul>
                    <p>These activities are specially oriented to develop the dormant potentials in the young boys & girls. Quiz, Debates, Symposiums, Projects, Science Fair & Exhibitions, Field Trips etc. are regular features of curriculum. To inculcate competitive spirit among them, all students are grouped into four houses, i.e, Agastyamalai, Aravali, Nilgiri, Sahyadri and competetion are held among different Houses. Each House is guided by a House Master and a House Mistress. </p>

                    <h3 className="subhead">Month Wise Competition</h3>
                    <p>Apart from the extra and co-curricular activities, the Science Club, Literary Club, Media Club and Heritage & Yuva Tourism Club, SEWA Club, Philately Club, & Eh Bharat Shreshta Bharat Club, Nature Clubs are functioning in the school giving exposure and exploring the potential of each student.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default ActivityCorner

