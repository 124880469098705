import React, { useEffect } from "react";
import { Link } from 'react-router-dom' 
const SubjectStudy = () => {
  useEffect(() => {
    document.title = 'Subject  of Study - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Subject  of Study</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Subject of Study</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-12">
  
                <div className="book">
                  <p> The School is affiliated to the CBSE and comes under the jurisdiction of the&nbsp; Patna  Regional office.    The medium of instruction is English. The school gives its students the chance to experience a variety of subjects at various grade levels . Scholastic and Co-scholastic subjects are part of the curriculum and vocational skill subjects from class VI onwards. </p>
          
                </div>
                <div className="clr10"></div>
          
                      <div className="table-responsive">
                    <table className="table">          
                        <tbody><tr>
                        <th>GRADE</th>
                        <th>SCHOLASTIC SUBJECTS</th>
                        <th>CO-SCHOLASTIC SUBJECTS</th>
                        <th>SKILL/VOCATIONAL SUBJECTS</th>
                        </tr>   
                        <tr>
                        <td>I - III</td>
                        <td>ENGLISH,MATH,EVS,HINDI</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>-</td>
                        </tr>  
                                      <tr>
                        <td>IV</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>-</td>
                        </tr>  
                                      <tr>
                        <td>V</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI,SANSKRIT</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>-</td>
                        </tr>  
                                      <tr>
                        <td>VI</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI,SANSKRIT</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>COMPUTER SCIENCE ,HANDICRAFT,CODING</td>
                        </tr>  
                                      <tr>
                        <td>VII</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI,SANSKRIT</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>COMPUTER  SCIENCE,DESIGN THINKING,CODING</td>
                        </tr>  
                                      <tr>
                        <td>VIII</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI,SANSKRIT</td>
                        <td>GK,MORAL SCIENCE,ART,MUSIC,HEALTH & PHYSICAL EDUCATION</td>
                        <td>COMPUTER  SCIENCE,ARTIFICIAL INTELLIGENCE,DATA SCIENCE, CODING</td>
                        </tr>  
                                      <tr>
                        <td>IX-X</td>
                        <td>ENGLISH,MATH,SCIENCE,SOCIAL SCIENCE,HINDI/SANSKRIT</td>
                        <td>WORK EDUCATION,ART EDUCATION ,HEALTH & PHYSICAL EDUCATION</td>
                        <td>AI/IT/MAS/MSFC/PAT/DESIGN THINKING & INNOVATION</td>
                        </tr>        
                        </tbody></table>
                        </div>
                        
          
          
                <div className="clr10"></div>
                <h3 className="subhead">Stream Wise Eligibility Criteria for Class XI Admission</h3>
                  <div className="table-responsive">
                    <table className="table">
                    
                        <tbody><tr>
                           <th rowspan="2">Stream</th>
                           <th colspan="3" style={{textAlign:"center"}}>Subject Offered</th>
                           <th colspan="2" style={{textAlign:"center"}}>Percentage Required</th>
                        </tr>
                        <tr>
                           <th>Main Subjects</th>
                           <th>5<sup>th</sup> Option</th>
                           <th>6<sup>th</sup> Option</th>
                           <th>MGM</th>
                           <th>Non MGM</th>
                        </tr>
          <tr>
          <td>PCM</td>
          <td>ENG,PHY,CHEM,MATH</td>
          <td>IT,AI,IP,CS,ECONOMICS</td>
          <td>PAT/MUSIC/PAINTING</td>
          <td>80% & Above</td>
          <td>85% & Above</td>
          </tr>
          <tr>
          <td>PCB</td>
          <td>ENG,PHY,CHEM,BIO</td>
          <td>IT, MATH</td>
          <td>YOGA/ECCE/MUSIC/PAINTING</td>
          <td>80% & Above</td>
          <td>85% & Above</td>
          </tr>
          <tr>
          <td>COMMERCE</td>
          <td>ENG,ACC,B.St,ECONOMICS</td>
          <td>APPLIED MATH,IT,IP</td>
          <td>PAT/MUSIC/PAINTING/APP.MATH</td>
          <td>60% & Above</td>
          <td>70% & Above</td>
          </tr>
          <tr>
          <td>ARTS</td>
          <td>ENG,ECO, POLITICAL SCIENCE , SOCIOLOGY  </td>
          <td>IT,RETAIL,PSYCHOLOGY</td>
          <td>PAT/MUSIC/PAINTING /ECCE</td>
          <td>50% & Above</td>
          <td>55% & Above</td>
          </tr>
          <tr>
          <td>-</td>
          <td>IT = INFORMATION TECHNOLOGY<br/>
          IP = INFORMATICS PRACTICES<br/>
          AI = ARTIFICIAL INTELLIGENCE<br/>
          CS = COMPUTER SCIENCE</td>
          <td>ECCE = EARLY CHILDHOOD CARE AND EDUCATION <br/>
          PAT = PHYSICAL ACTIVITY TRAINER<br/>
          MAS = MARKETING AND SALES</td>
          <td>MSFC = MULTI SKILL FOUNDATION COURSE<br/>
          ACC = ACCOUNTS<br/>
          B.St = BUSINESS STUDIES<br/>
          ECO = ECONOMICS</td>
          <td>-</td>
          <td>-</td>
          </tr>
          
               
                    </tbody></table>
                  </div>
                  </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default SubjectStudy

