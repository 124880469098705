import React, {  useEffect } from 'react';
import { Link } from 'react-router-dom' 
const TinkeringLab = () => {
  useEffect(() => {
    document.title = 'Tinkering Lab - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  

  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Infrastructure </li>
                 <li>Tinkering Lab</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Tinkering Lab</h1>
                    </div>
                </div>
                <div className="row"> 
                   <div className="col-12"> 
                        
                        <p>Atal Tinkering Lab is the flagship initiative of Atal Innovation Mission (AIM), Government of India to cultivate an innovative mindset amongst high school students across the country.</p>
                       
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                             <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab1.jpg">
                               <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab1.jpg" alt="mgmb"/> 
                             </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                             <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab2.jpg">
                               <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab2.jpg" alt="mgmb"/> 
                             </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                             <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab3.jpg">
                               <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab3.jpg" alt="mgmb"/> 
                             </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                             <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab4.jpg">
                               <img className="img-fluid mx-auto d-block smartclass" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Tinkering-lab4.jpg" alt="mgmb"/> 
                             </a>
                            </div>
                         </div> 
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default TinkeringLab

