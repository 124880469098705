import React, { useEffect } from 'react';
import { Link } from 'react-router-dom' 
const Scholarship = () => {
    useEffect(() => {
        document.title = 'Scholarship & Fee Concessions - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Scholarship & Fee Concessions </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Scholarship & Fee Concessions </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <div className="media">
                    
                      <p><strong>1.</strong> In special cases students facing financial challenges will be considered for fee concessions after proper scrutiny.</p>
                    
                  </div>
                  <div className="media">
                    
                      <p><strong>2.</strong> The concession shall be tenable for one academic year (April to March) only. Every year fresh applications shall be submitted for renewal.</p>
                    
                  </div>
                  <div className="media">
                    
                      <p><strong>3.</strong> Those who seek concession in the aforementioned cases only shall apply to the Vice President invariably in the month of March with all supporting documents. No such request will be entertained after the expiry of the deadline. Concession shall not be continued if a student fails to get a minimum of B1 grade in all the compulsory subjects in the final assessment.</p>
                  
                  </div>
                  <div className="media">
                    
                      <p><strong>4.</strong> Special Scholarship to students scoring 90% and above in Class X Board examination is given beside cash awards to X and XII CBSE toppers. </p>
                    </div>
                  
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Scholarship

