import React, { useEffect } from "react"; 
import { Link } from 'react-router-dom'  
import Slider from "react-slick";
const Library = () => {
  useEffect(() => {
    document.title = 'Library - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 4,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Infrastructure</li>
                 <li>Library</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Library</h1>
                    </div>
                </div>
                 <div class="row">
                     <div class="col-lg-12">
                     <p>M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel City is one of the best English Medium Co-Education school. The library boasts an impressive collection of 18163 books and 858 reference books, catering to a wide range of interests and age groups. Whether you're a budding historian seeking factual accounts of the past, a creative writer yearning to get lost in a fictional world, or a curious student looking to expand your knowledge in a particular subject, the library is bound to have something for you.</p>
                     <p>The collection likely includes a variety of genres, such as:</p>
                    <ul class="ullist">
                      <li>Fiction: Classics, young adult fiction, science fiction, fantasy, mystery, romance, and more.</li>
                      <li>Non-Fiction: History, science, biography, art, music, literature, current events, and more.</li>
                      <li>Reference materials: Encyclopedias, dictionaries, atlases, and other resources for research.</li>
                    </ul>
                    
                    <h3 class="subhead">Periodicals, Magazines, and Newspapers</h3>
                    
                    <p>In addition to books, the library also offers a selection of periodicals, magazines, and newspapers to keep you informed and engaged with current events and popular topics.</p>
                    
                    <ul class="ullist">
                     <li><b>Periodicals (15):</b> These are publications, often released monthly or quarterly, that focus on specific subjects or areas of interest. Examples might include science journals, literary magazines, or educational periodicals. </li>
                    <li><b>Magazines (15):</b> Magazines cover a broad range of topics in a more casual and visually appealing format than periodicals. Popular science magazines, fashion magazines, and news magazines are some common examples. </li>
                    <li><b>Newspapers (9):</b> Newspapers provide daily updates on current events, local news, and world affairs. They are a valuable resource for staying informed about what's happening around the globe. </li>
                    </ul>
                    <h3 class="subhead">Finding What You Need</h3>
                    
                    
                    <p>The library staff is always happy to assist you in finding the resources you need. Don't hesitate to ask a librarian for help navigating the library's collection or using the library catalog system.</p>
                    
                    <p>The library likely has a designated fiction section, a non-fiction section, and possibly separate areas for periodicals, magazines, and newspapers. Shelving within these sections might be organized by genre, author, or Subject wise Classification System.</p>
                    
                    <h3 class="subhead">Additional Resources</h3>
                    
                    <p>Many libraries offer additional resources and services beyond their physical collections. Here are some possibilities your school library might explore:</p>
                    
                    <ul class="ullist">
                    <li>E-books and audiobooks: Access to a digital collection of books that can be borrowed and enjoyed on electronic devices.</li>
                    
                    <li>Online databases: Subscriptions to online databases that provide access to scholarly articles, journals, and other research materials.</li>
                    
                    <li>Library programs and events: Author talks, book clubs, workshops, and other events that promote literacy and engagement with the library.</li>
                    </ul>
                    <p>By taking advantage of the wealth of resources available in your school library, you can empower yourself to become a lifelong learner and explore the world of knowledge.</p>
                     </div>
                     <h3 class="subhead"> CBSE Curriculum Class XI-XII for the Academic Year 2023-24</h3>
                     <div class="curricbox">
                     
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Hindi_Core.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Hindi.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Hindi Core</p></div></Link>
                 
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Physics.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Physics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Physics</p></div></Link>
               
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Informatics_Practices.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/InformaticsPractices.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Informatics Practices</p></div></Link>
                
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Geography.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Geography.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Geography </p></div></Link>
                 
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/English_core.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/EnglishCore.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>English Core </p></div></Link>
               
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Chemistry.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Chemistry.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Chemistry </p></div></Link>
                
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Accountancy.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Accountancy.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Accountancy </p></div></Link>
               
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/History.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/History.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>History </p></div></Link>
               
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Biology.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Biology.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Biology </p></div></Link>
               
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Maths.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Mathematics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Mathematics </p></div></Link>
              
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/BusinessStudies.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/BusinessStudies.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Business Studies </p></div></Link>
                
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/PoliticalScience.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/PoliticalScience.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Political Science </p></div></Link>
                 
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/BioTechnology.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Bio.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Bio Technology </p></div></Link>
              
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Computer_Science.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Computer.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Computer Science</p></div></Link>
                
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Economics.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Economics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Economics  </p></div></Link>
             
                
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/PhysicalEducation.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/PhysicalEducation.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div>
                      <div class="curriculumdesc"><p>Physical Education  </p></div></Link>
                
                     </div>
                     <h3 class="subhead">CBSE Additional Practice Questions for Class XII 2023-24 </h3>
                     <div class="curricbox"> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/AccountancyPQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Accountancy.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Accountancy <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Biology-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Biology.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Biology <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/BusinessStudies-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/BusinessStudies.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Business Studies <span>Practice Questions | MS</span> </p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Chemistry-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Chemistry.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Chemistry <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Economics_PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Economics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Economics  <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/EnglishCore-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/EnglishCore.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>English Core <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Geography-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Geography.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Geography <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Hindi-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Hindi.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Hindi Core <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/History-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/History.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>History <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Maths-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Mathematics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Mathematics <span>Practice Questions | MS</span></p></div>
                     </Link> 
                     <Link class="curriculumbox" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Physics-PQ.pdf" target="_blank"> <div class="curriculumboximg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/Physics.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></div> <div class="curriculumdesc"><p>Physics <span>Practice Questions | MS</span></p></div>
                     </Link>
                      </div>
                      <h3 class="subhead"> E-Book </h3>
                      <Slider {...settings}>  
                           <div className="item">
                             <div className="ebookdiv"> 
                                   <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/jeet-apki-shiv-khera.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook1.PNG" className="img-fluid" alt="MGM bokaro"/> </Link>
                              </div>
                          </div> 
                          <div className="item">
                          <div className="ebookdiv"> 
                                <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Rich-dad-poor-dad.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook2.PNG" className="img-fluid" alt="MGM bokaro"/> </Link>
                           </div>
                       </div>
                       <div className="item">
                       <div className="ebookdiv"> 
                             <Link to="https://ncert.nic.in/textbook.php" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook.jpg" className="img-fluid" alt="MGM bokaro"/> </Link>
                        </div>
                    </div>
                    <div className="item">
                    <div className="ebookdiv"> 
                          <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/SUMAN-Malala-Hoon-Main.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook4.PNG" className="img-fluid" alt="MGM bokaro"/> </Link>
                     </div>
                 </div>
                 <div className="item">
                 <div className="ebookdiv"> 
                       <Link to="https://byjus.com/ncert-books/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook.jpg" className="img-fluid" alt="MGM bokaro"/> </Link>
                  </div>
              </div>
              <div className="item">
              <div className="ebookdiv"> 
                    <Link to="https://books.ebalbharati.in/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook7.PNG" className="img-fluid" alt="MGM bokaro"/> </Link>
               </div>
           </div>
           <div className="item">
           <div className="ebookdiv"> 
                 <Link to="https://www.kaggle.com/datasets/hinepo/harry-potter-books-in-pdf-1-7" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook.jpg" className="img-fluid" alt="MGM bokaro"/> </Link>
            </div>
        </div>
        <div className="item">
        <div className="ebookdiv"> 
              <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/001-Agni-Ki-udan-Hindi.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook9.PNG" className="img-fluid" alt="MGM bokaro"/> </Link>
         </div>
     </div>
     <div className="item">
     <div className="ebookdiv"> 
           <Link to="https://play.google.com/store/apps/details?id=national.digital.library&pli=1" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/ebook10.png" className="img-fluid" alt="MGM bokaro"/> </Link>
      </div>
     </div>
                       </Slider>
                      <div className="ebookslink">
                        <div className="row">
                        <div className="col-lg-3">
                        <Link to="https://www.kristujayanti.edu.in/Library/E-Books.html" target="_blank">KJC Library</Link>
                      </div>
                      <div className="col-lg-3">
                          <Link to="https://diksha.gov.in/" target="_blank"> Diksha </Link>
                    </div>
                    <div className="col-lg-3">
                          <Link to="https://ndl.iitkgp.ac.in/" target="_blank">  National Digital Library of India </Link>
                    </div>
                    <div className="col-lg-3">
                          <Link to="https://worldstories.org.uk/" target="_blank">  World Stories </Link>
                    </div>
                    <div className="col-lg-3">
                          <Link to="https://swayam.gov.in/" target="_blank"> Swayam </Link>
                    </div>
                    <div className="col-lg-3">
                    <Link to="http://www.math.com/" target="_blank"> The World of Math online </Link>
              </div>
                        </div>
                  </div> 
                 </div>
            </div>
        </div>
     </>
  )
}

export default Library

