import React from 'react'
import { NavLink } from 'react-router-dom'
import Highlights from '../Component/Highlights'
import { Link } from 'react-router-dom'
const Header = () => {
    return (
        <>
            {/* <div className="topheader">
            <div className="topheaderleft">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/phone.gif" className="img-fluid"/>
                <p><Link to="tel: +91 9424746251">+91 9424746251</Link> 
                </p>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/mail.gif" className="img-fluid"/>
                <Link to="mailto: gandhihighschoolsidhi@gmail.com">gandhihighschoolsidhi@gmail.com </Link>
            </div>
          
            <div className="topheaderright">
            <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/twiter.png" className="img-fluid"/></Link>
            <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/youtube.png" className="img-fluid"/></Link>
            <Link to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/fb.png" className="img-fluid"/></Link>
            </div>
      </div> */}
            <div className="header">

                <div className="logo">
                    <NavLink to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/logo.png" alt="MGMB" className="img-fluid" /></NavLink>
                </div>
                <div className="header-nav">
                    <Highlights />
                    <nav className="navbar navbar-expand-lg">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item active"> <Link to="/"><i className="bi bi-house-door-fill"></i></Link></li>
                                <li className="nav-item dropdown">

                                    <div className="nav-link" data-bs-toggle="dropdown">  School </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/OurSchool">Our School</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurChurch">Our Church</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurPatron">Our Patron</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/MissionVision">Mission &  Vision, Motto &  Aim </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurPrincipal">Our Principal</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/hod">Head of Departments </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/WorkingCommittee">School Managing Committee</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Faculty">Faculty</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SchoolTiming">School Timing</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/PrayerAnthem">Prayer &  Anthem</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/CareerGuidance">Career Guidance &  Counselling</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="http://cbseacademic.nic.in/" target="_blank">CBSE Website</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/almanac.pdf" target="_blank">Almanac</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/schoolCommunities">School Communities</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">   ACADEMICS </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/FeeDetails">Fee Details</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/StudentCorner">Student Corner</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="https://drive.google.com/file/d/1m2hXagFnN3K1dztu71A4SdDlNLXGpMzg/view?usp=sharingand" target="_blank">Mandatory Disclosure</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SubjectStudy">Subjects of Study</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Tc">TC</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ActivityCorner">Educational Activities</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SamplePaper">Sample Paper </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Syllabus">Syllabus</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Booklist">Book list</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/admission">Admission</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/scholarship">Scholarship</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/counselling">Counsellor</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/achievement">Achievement</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/notice">Notice</NavLink></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">  INFRASTRUCTURE </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li> <NavLink className="dropdown-item" to="/Facilities">Facilities</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/ClassRoom">Class Room</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Laboratories">Laboratories</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Library">Library</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/PlayGround">Play Ground</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/TinkeringLab">Tinkering Lab</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><Link to="https://www.mgmbcampuscare.in/" target="_blank">ALUMNI</Link>  </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">  MEDIA </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li> <NavLink className="dropdown-item" to="/BuildingProgram">Capacity Building Program</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Activity">Activity</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Gallery">School Gallery</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/EMagazine">E - Magazine</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Certificates">Certificates </NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">  HELP </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li> <NavLink className="dropdown-item" to="/ErpSoftware">ERP Software</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="/Pphelp">Portal (Help?)</NavLink></li>
                                        <li> <NavLink className="dropdown-item" to="#">Sitemap</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link blinker" ><NavLink to="https://www.mgmbcampuscare.in/" target='_blank'>Admission </NavLink></div>
                                     
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
        </>
    )
}

export default Header
