import React, { useEffect, useState } from 'react'
import Slider from "react-slick"; 
import { getBirthdays } from '../Service/Api';
const Birthday = () => { 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getBirthdays();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/trophy.png" },   
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/trophy.png" },   
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/trophy.png" }  
  ];
  return (
    <>
      <div className="row">
        <Slider {...settings}>
        
        {data.length > 0 ? (data.map((item, index) => {  
          const date = new Date(item.date);
          const mon = monthNames[date.getMonth()];
          const eventdate = date.getDate();
               console.log(mon);

           return (
          <div key={index}>
                <div className="item">
              <div className="topperdiv birthday">
                <div className="toppertaj">
                  <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="img-fluid" />
                </div>

                <div className="topperdesc">
                  <p className="name">{item.name}</p>
                  <p className="name">{item.class}</p>
                  <p className="per">{eventdate} {mon}</p>
                </div>
              </div>
            </div> 
          </div>  
          )
        })) : (emptyArray.map((data, index) => (
        <div key={index}>
            <div className="item">
              <div className="topperdiv birthday">
                <div className="toppertaj">
                  <img src={data.attachments} className="img-fluid" />
                </div>

                <div className="topperdesc">
                  <p className="name">{data.name}</p>
                  <p className="name">{data.class}</p>
                  <p className="per">DD MM </p>
                </div>
              </div>
            </div>  
          </div>  
        )))}  
        </Slider>
      </div>
    </>
  )
}

export default Birthday