import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom' 
const Achievement = () => {
  useEffect(() => {
    document.title = 'School Communities - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                 <li>  School </li>
                 <li>School Communities </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Communities </h1>
                        <p>At Mar Gregorios Memorial Higher Secondary School,  Bokaro Steel City, we believe that a strong sense of community and teamwork is essential for a well-rounded education. To foster this spirit, our students are divided into four Houses, each named after a majestic mountain range in India. These houses serve as a platform for students to develop essential life skills, build lasting relationships, and create unforgettable memories.</p>
                        <p><b>Meet Our Four Houses:</b></p>
                        <p>Agasthyamalai | Nilgiri | Aravalli | Sahyadri</p>
                    </div>
                </div>
                <div className="row">
                   <div className='col-md-6'>
                    <div className='houses blue-house'>
                        <h3>Agasthyamalai House</h3>
                        <p>Named after the majestic Agasthyamalai hills, this house embodies courage and resilience. Students of Agasthyamalai house are known for their leadership skills and teamwork. Their motto, "Scale New Heights," inspires them to strive for excellence. Agasthyamalai house fosters a spirit of adventure and exploration. Through various activities, students develop essential life skills.</p>
                    </div>
                   </div>
                   <div className='col-md-6'>
                    <div className='houses red-house'>
                        <h3>Nilgiri House</h3>
                        <p>Inspired by the breathtaking Nilgiri mountains, this house represents serenity and wisdom. Nilgiri house students excel in intellectual pursuits and creative endeavors. Their motto, "Knowledge is Power," drives them to seek wisdom. Nilgiri house encourages critical thinking and innovation. Members develop into thoughtful and empathetic individuals.</p>
                    </div>
                   </div>
                   <div className='col-md-6'>
                    <div className='houses green-house'>
                        <h3>Aravalli House</h3>
                        <p>Derived from the ancient Aravalli range, this house symbolizes strength and unity. Aravalli house students demonstrate exceptional teamwork and camaraderie. Their motto, "United We Stand," promotes harmony and cooperation. Aravalli house cultivates a sense of community and social responsibility. Students become confident and compassionate leaders.</p>
                    </div>
                   </div>
                   <div className='col-md-6'>
                    <div className='houses yellow-house'>
                        <h3>Sahyadri House</h3>
                        <p>Named after the majestic Sahyadri mountains, this house embodies vitality and enthusiasm. Sahyadri house students showcase their talents in sports and cultural activities. Their motto, "Reach New Peaks," motivates them to push boundaries. Sahyadri house fosters a spirit of friendly competition and creativity. Members grow into dynamic and charismatic individuals.</p>
                    </div>
                   </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3>Clubs at MGM Bokaro</h3>
                        <p>At MGM HSS Bokaro, we believe that co-curricular activities are essential for a well-rounded education. Our diverse range of Clubs provides students with opportunities to explore their passions, develop new skills, and build lasting relationships. Whether it's arts, sports, community service, or academics, our Clubs cater to various interests.</p>
                        <p><b>Explore Our Clubs:</b></p>
                        <h5>Lakshya IAS Hub</h5>
                        <p>Welcome to Lakshya IAS Hub, where aspiring leaders are nurtured! This club aims to inspire and prepare students for civil services exams, fostering leadership skills, social responsibility, and civic awareness. Through interactive sessions, workshops, and mentorship programs, members develop essential skills for effective governance. Our goal is to empower students to become change-makers in society. By joining Lakshya IAS Hub, students embark on a journey of self-discovery and nation-building.</p>
                        <h5>Media Club</h5>
                        <p>Unleash your creativity with our Media Club! This vibrant group of students captures the essence of school life through photography, videography, and journalism. Members develop their storytelling skills, covering events, and sharing stories that matter. Our Media Club fosters teamwork, innovation, and self-expression. Radio MGM and Campus News are two unique highlights of the Media Club. Join us to showcase your talent and tell the untold stories of our school community.</p>
                        <h5>Literary Club</h5>
                        <p>Step into the world of words with our Literary Club! This haven for book lovers encourages reading, writing, debating, and creative expression. Members engage in literary analyses, author studies, and poetry recitals, nurturing their literary talents. Our club fosters critical thinking, empathy, and effective communication. Join us to explore the power of language and imagination.</p>
                        <h5>Nature and Eco Club</h5>
                        <p>Join the green revolution with our Nature and Eco Club! This eco-friendly group promotes environmental awareness, conservation, and sustainability. Members participate in tree planting, waste management, and eco-friendly initiatives, making a positive impact on our community. Our club inspires students to become responsible stewards of the planet. Together, we can create a cleaner, greener future.</p>
                        <h5>Science Club</h5>
                        <p>Ignite your curiosity with our Science Club! This dynamic group explores STEM concepts through hands-on experiments, projects, and competitions. Members develop problem-solving skills, critical thinking, and innovation. Our Science Club fosters a love for scientific inquiry and discovery. Join us to unravel the wonders of science and technology.</p>
                        <h5>Philately and Tourism Club</h5>
                        <p>Explore the world with our Philately and Tourism Club! This unique club discovers cultures through stamps, coins, and travel. Members learn about geography, history, and cultural heritage, broadening their horizons. Our club inspires students to become global citizens, appreciating diversity and complexity. Join us to embark on a journey of discovery and exploration.</p>
                        <h5>Ek Bharat Shreshth Bharat Club</h5>
                        <p>Celebrate India's diversity with our Ek Bharat Shreshth Bharat Club! This club promotes cultural exchange, national integration, and social cohesion. Members engage in cultural events, language learning, and community outreach programs. Our club fosters patriotism, empathy, and unity. Join us to strengthen the fabric of our nation.</p>
                        <h5>SEWA (Social Empowerment through Work Education and Action)</h5>
                        <p>Empower through service with SEWA! This compassionate club focuses on community outreach, social empowerment, and volunteer work. Members develop essential life skills, compassion, and responsibility. Our SEWA Club inspires students to become change-makers, making a positive impact in society. Join us to serve, learn, and grow.</p>
                        <h5>Cyber and Interact Club</h5>
                        <p>Code, connect, and serve with our Cyber and Interact Club! This tech-savvy and service-oriented group combines programming skills, digital literacy, and community service. Members participate in coding competitions, hackathons, tech projects, and volunteer work, fostering creativity, problem-solving, and social responsibility. Join us to shape the digital landscape and make a difference.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Achievement

