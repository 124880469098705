
import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const Hod = () => {
    useEffect(() => {
        document.title = 'Head of Departments - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Head of Departments </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Head of Departments </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <Tabs className="Tabs">
                    <TabList>
                        <Tab>Senior Secondary Wing</Tab>
                        <Tab>Primary wing</Tab> 
                    </TabList>
                    <TabPanel> 
                          <h4>Teacher’s Portfolio 2024 -25 to All the Teachers :-Teachers responsible for different offices for the academic year 2024 -25.</h4>
                          <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Teachers_Portfolio_senior_wing.pdf" width="100%" height="900px;"></iframe>
                    </TabPanel>
                     <TabPanel> 
                        <h4> Staff Portfolio 2024 -25</h4>
                        <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/Staff_Portfolio_primary_wing.pdf" width="100%" height="900px;"></iframe>
                    </TabPanel>
                     </Tabs>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Hod

