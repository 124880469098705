import { useEffect  } from "react";
import { Link } from 'react-router-dom' 
const BusRoute = () => {
    useEffect(() => {
        document.title = 'Bus Route - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>Academic</li>
                 <li>Bus Route</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Bus Route</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="subhead">Senior Wings </h3>
                        <div className="clr15"></div>
                        <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/SUMMER_BUS_TIMING-sr.pdf#toolbar=0" width="100%" height="600px"></iframe>
                    </div>
                    <div className="col-md-12">
                        <h3 className="subhead">Junior Wings </h3>
                        <div className="clr15"></div>
                        <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/SUMMER_BUS_TIMING-jr.pdf#toolbar=0" width="100%" height="600px"></iframe>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default BusRoute

