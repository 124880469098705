
import React, { useEffect  } from "react";
import { Link } from 'react-router-dom' 
const Counselling = () => {
    useEffect(() => {
        document.title = 'Counselling - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>School Counsellor</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Counsellor</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="counsel">
                           <p>A counsellor is appointed to address the issues of adolescence by consulting the students & conducting Periodic workshops.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Counselling

