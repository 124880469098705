import React, { useEffect } from "react";
import { Link } from 'react-router-dom' 
const Syllabus = () => {
    useEffect(() => {
        document.title = 'Syllabus - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Syllabus</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Syllabus</h1>
                    </div>
                </div>
                <div className="row">
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-1.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class I</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-2.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class II</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-3.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class III</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-4.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class IV</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-5.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class V</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-6.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class VI</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-7.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class VII</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-8.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class VIII</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-9.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class IX</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-10.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class X</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
              <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-11.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class XI</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div> 
             <div className="col-lg-4 col-md-6"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/pdf/syllabus/2023/Class-12.pdf#toolbar=0" target="_blank"> <div className="sample-blk"> <p>Class XII</p> <img alt="mgm" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
             </div>
            </div>
        </div>
     </>
  )
}

export default Syllabus

