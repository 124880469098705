import React, {useEffect } from 'react';
import { Link } from 'react-router-dom' 
const PlayGround = () => {
    useEffect(() => {
        document.title = 'Play Ground - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Infrastructure </li>
                 <li>Play Ground</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Play Ground</h1>
                    </div>
                </div>
                <div className="row"> 
                   <div className="col-12"> 
                        
                        {/* <p>Atal Tinkering Lab is the flagship initiative of Atal Innovation Mission (AIM), Government of India to cultivate an innovative mindset amongst high school students across the country.</p> */}
                       
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground1.png"><img className="img-fluid mx-auto d-block" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground1.png" alt="mgmb"/> 
                            </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground2.png"><img className="img-fluid mx-auto d-block" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground2.png" alt="mgmb"/> 
                            </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground3.png"><img className="img-fluid mx-auto d-block" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground3.png" alt="mgmb"/> 
                            </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground4.png"><img className="img-fluid mx-auto d-block" src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/play-ground4.png" alt="mgmb"/> 
                            </a>
                            </div>
                         </div> 
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default PlayGround

